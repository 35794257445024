import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { button } from "../../text/button.text";
import { title } from "../../text/page-title.text";
import { href } from "../../utils/ui-urls";
import { formTitle } from "../../text/form-title.text";
import PasswordChecklist from "../../components/password-checklist/password-checklist.component";
import {
  InputBase,
  InputPassword,
  Button,
  Title,
  ProgressIndicator,
  Label,
} from "@creditonebank/c1b-components";
import { label } from "../../text/labels.text";
import { passwordValidate } from "../../text/password-validation.text";
import { validatePassword } from "../../functions/validate-password.function";
import icon from "../../assets/SetUpIcon.svg";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { errorResponse } from "../../text/error.responses.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkState } from "../../functions/check-state.function";
import { HelpLink } from "../../components/link/help-link.component";
import { isInvalidUsername } from "../../functions/validate-username.function";

const ActivateUser = ({
  baseAPI,
  stepNumber,
  stepNames,
  mobileStepNames,
  helpLink,
  usernameRestrictRegex,
  usernameValidationErrorDescription,
}) => {
  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [usernameLabelError, setUsernameLabelError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = title.activateUser;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    validatePassword(newPassword, setValidate);
  }, [newPassword]);

  useEffect(() => {
    if(document.getElementById("pw__input__adv__helper")) {
      document.getElementById("pw__input__adv__helper").style.color = "#c60f13"; }
  }, [confirmNewPasswordError]);

  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    hasLength8: false,
    hasSpecialCharacter: false
  });

  const strength = Object.values(validate).reduce((a, item) => a + item, 0);

  function handleLabelError() {
    if (username.length < 6) {
      setUsernameLabelError(true);
    } else if (username.includes(" ")) {
      setUsernameLabelError(true);
      document.getElementById("username__input__adv__helper").classList.add("InputBase__error_or_desc_text__error");
      document.getElementById("username").classList.add("InputBase__input__error");
    } else {
      setUsernameLabelError(false);
      document.getElementById("username__input__adv__helper").classList.remove("InputBase__error_or_desc_text__error");
      document.getElementById("username").classList.remove("InputBase__input__error");
    }
  }

  function handleChange() {
    setError(false);
    setUsernameError(false);
    setUsernameLabelError(false);
    setNewPasswordError(false);
    setConfirmNewPasswordError(false);
    handleLabelError();
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setUsernameError(false);
    setNewPasswordError(false);
    setConfirmNewPasswordError(false);

    if (
      username === "" &&
      newPassword === "" &&
      confirmNewPassword === "") {
      setErrorTitle(passwordValidate.blankValuesValidateUser2.errorTitle);
      setErrorDescription(passwordValidate.blankValuesValidateUser2.errorDesc);
      setError(true);
      window.scrollTo(0, 0);
    } else if (newPassword === "" && confirmNewPassword === "") {
      setErrorTitle(passwordValidate.blankPasswordsValidateUser2.errorTitle);
      setErrorDescription(
        passwordValidate.blankPasswordsValidateUser2.errorDesc
      );
      setNewPasswordError(true);
      setConfirmNewPasswordError(true);
      window.scrollTo(0, 0);
    } else if (username === "" && newPassword === "") {
      setErrorTitle(passwordValidate.missingUserameAndPassword.errorTitle);
      setErrorDescription(passwordValidate.missingUserameAndPassword.errorDesc);
      setUsernameError(true);
      setNewPasswordError(true);
      window.scrollTo(0, 0);
    } else if (username === "" && confirmNewPassword === "") {
      setErrorTitle(passwordValidate.blankUsernameConfPass.errorTitle);
      setErrorDescription(passwordValidate.blankUsernameConfPass.errorDesc);
      setUsernameError(true);
      setConfirmNewPasswordError(true);
      window.scrollTo(0, 0);
    } else if (confirmNewPassword === "") {
      setErrorTitle(passwordValidate.blankConfirmPassword.errorTitle);
      setErrorDescription(passwordValidate.blankConfirmPassword.errorDesc);
      setConfirmNewPasswordError(true);
      window.scrollTo(0, 0);
    } else if (
      username === ""
    ) {
      setErrorTitle(passwordValidate.blankUsernameConfPass.errorTitle);
      setErrorDescription(errorMsg.missingUsername);
      setUsernameError(true);
      window.scrollTo(0, 0);
    } else if (isInvalidUsername(username, usernameRestrictRegex)) {
      setErrorTitle(errorResponse.newUsernameValidationError);
      setErrorDescription(usernameValidationErrorDescription);
      setUsernameError(true);
      window.scrollTo(0, 0);
    } else {
      if (newPassword.match(passwordValidate.regex)) {
        if (newPassword === confirmNewPassword) {
          setShowLoader(true);
          axiosInstance
            .post(baseAPI + state.id + api.activateUser, {
              newLogin: username,
              password: newPassword,
            })
            .then((response) => {
              setShowLoader(false);
              setError(false);
              if (response.data.status === "ACTIVE") {
                navigate(href.enrollSecurityQuestions, {
                  state: { id: state.id },
                  replace: true,
                });
              }
            })
            .catch((error) => {
              setShowLoader(false);
              if (checkServerError(error.response.status)) {
                setErrorTitle(errorMsg.internalServer.errorTitle);
                setErrorDescription(errorMsg.internalServer.errorDesc);
                setError(true);
              } else if (
                error.response.data.error.title === errorResponse.passwordError
              ) {
                setErrorTitle(error.response.data.error.title);
                setErrorDescription(error.response.data.error.message);
                setNewPasswordError(true);
                setConfirmNewPasswordError(true);
                window.scrollTo(0, 0);
              } else if (
                error.response.data.error.title ===
                errorResponse.newUsernameError ||
                error.response.data.error.title ===
                errorResponse.newUsernameValidationError
              ) {
                setErrorTitle(error.response.data.error.title);
                setErrorDescription(error.response.data.error.message);
                setUsernameError(true);
                window.scrollTo(0, 0);
              } else if (
                error.response.data.error.title ===
                errorResponse.accountAlreadyActiveError
              ) {
                navigate(href.accountActive);
              } else {
                setErrorTitle(error.response.data.error.title);
                setErrorDescription(error.response.data.error.message);
                setError(true);
                window.scrollTo(0, 0);
              }
            });
        } else {
          setErrorTitle(
            passwordValidate.passwordsNoMatchValidateUser.errorTitle
          );
          setErrorDescription(
            passwordValidate.passwordsNoMatchValidateUser.errorDesc
          );
          setNewPasswordError(true);
          setConfirmNewPasswordError(true);
          window.scrollTo(0, 0);
        }
      } else {
        setNewPasswordError(true);
        setErrorTitle(passwordValidate.passwordsNoMatch.errorTitle);
        setErrorDescription(passwordValidate.passwordsNoMatch.errorDesc);
        window.scrollTo(0, 0);
      }
    }
  }

  return (
    <div className="bg">
      <div className="progress-stepper-style">
        <ProgressIndicator
          className="web-progress-stepper"
          currentIndex={stepNumber}
          stepNames={stepNames}
        />
        <ProgressIndicator
          className="mobile-progress-stepper"
          currentIndex={stepNumber}
          stepNames={mobileStepNames}
        />
      </div>
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element-activate-user">
          <form onSubmit={(e) => handleSubmit(e)} className="fp-form">
            <div className="set-up-icon">
              <img src={icon} alt="set-up-icon" />
            </div>

            <div className="title">
              <Title tag="h2" align="center">
                {formTitle.activateUser}
              </Title>
              <Title tag="h2" align="center">
                {formTitle.activateUser2a}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={
                  error ||
                  usernameError ||
                  newPasswordError ||
                  confirmNewPasswordError
                }
              />
            </div>

            <div className="activate-user-input-fields">
              <Label error={error || usernameError || usernameLabelError}>
                {label.newUsername}
              </Label>
              <InputBase
                id="username"
                name="username-input"
                setValue={setUsername}
                type="username"
                minLength={6}
                maxLength={26}
                value={username}
                error={error || usernameError}
                hideAlertIcon
                required
                requiredMessage="Must have 6 or more characters and no spaces."
                invalidErrorMessage="Must have 6 or more characters and no spaces."
                description="Must have 6 or more characters and no spaces."
                autoFocus
                onChange={handleChange}
                onBlur={handleLabelError}
                data-testid="test-username"
              />
            </div>

            <InputPassword
              id="pw2"
              value={newPassword}
              label={label.password}
              labelClass={error || newPasswordError ? "label-error" : null}
              name="pw2-input"
              type="password"
              regex={passwordValidate.regex}
              error={error || newPasswordError}
              noErrorsOrHelper
              hideAlertIcon
              showHide={{
                showText: "show",
                hideText: "hide",
              }}
              setValue={setNewPassword}
              onChange={handleChange}
              minLength={8}
              maxLength={20}
              description="A strong password has a mix of letters, numbers, and special characters."
              data-testid="test-newPassword"
            />
            <PasswordChecklist password={newPassword} />

            <InputPassword
              id="pw"
              value={confirmNewPassword}
              label={label.reEnterPassword}
              labelClass={
                error || confirmNewPasswordError ? "label-error" : null
              }
              name="password-input"
              type="password"
              customValidation={[]}
              error={error || confirmNewPasswordError}
              noErrorsOrHelper
              hideAlertIcon
              showHide={{
                showText: "show",
                hideText: "hide",
              }}
              setValue={setConfirmNewPassword}
              onChange={handleChange}
              minLength={8}
              maxLength={20}
              description= {confirmNewPasswordError ? "Passwords do not match" : null}
              data-testid="test-confirmPassword"
            />

            {strength === 5 && confirmNewPassword !== "" &&
              username.length >= 6 &&
              !username.includes(" ") ? (
              <Button
                type="submit"
                onClick={handleSubmit}
                size="medium"
                className="button-style-2"
              >
                {button.activateUser}
              </Button>
            ) : (
              <Button
                variant="inactive"
                size="medium"
                className="button-style-2"
              >
                {button.activateUser}
              </Button>
            )}
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default ActivateUser;